import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  &.not-searchable {
    input {
      display: none;
    }
  }

  & > div {
    display: flex;
    width: 100%;
  }

  .react-dropdown-select {
    border: solid 1px #ececec;
    border-radius: 0;

    &, &:hover, &:focus, &:active {
      outline: none;
      box-shadow: none!important;
    }

    &:hover, &:focus, &:active, &:focus-within {
      border-color: #000;
    }

    &.dark {
      background-color: #000;
      border-color: #000;

      .react-dropdown-select-input, .react-dropdown-select-content {
        color: #FFF;

        &::placeholder {
          color: #FFF;
        }

        &:focus {
          &::placeholder {
            color: rgba(255, 255, 255, .2);
          }
        }
      }

      .react-dropdown-select-dropdown-handle, .react-dropdown-select-clear {
        color: #FFF;
      }
    }

    &.grey {
      background-color: #f7f7f7;
    }
  }

  .react-dropdown-select-content {
    padding: 10px 5px;
    justify-content: center;

    & > span:not([role=listitem]) {
      margin-left: 7px;
      padding-top: 5px;
      padding-bottom: 6px;
    }

    .react-dropdown-select-input {
      width: calc(12ch + 5px);
      margin-left: 5px;
    }
  }

  .react-dropdown-select-option {
    font-size: 13px;
    /* margin-top: 0;
    margin-bottom: 0; */
  }

  .react-dropdown-select-input, .react-dropdown-select-content {
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;

    @media ${device.tablet} {
      font-size: 15px;
    }

    &::placeholder {
      color: #000;
    }

    &:focus {
      &::placeholder {
        color: rgba(0, 0, 0, .2);
      }
    }
  }

  .react-dropdown-select-clear {
    &:hover {
      color: #000;
    }
  }

  .react-dropdown-select-option .react-dropdown-select-option-remove {
    &:hover {
      color: #FFF;
    }
  }
`;