import { CloudinaryFluid } from '../../../utils';
import Image from '../../../utils/Imgix';
import React from 'react';
import MagicLink from '../../../components/link';
import { Title } from './Insertion.styles';

const Insertion = ({ title = '', image, link, imageLoading }) => {
  return (
    <MagicLink href={link}>
      <Image
        fluid={CloudinaryFluid(image, { maxWidth: 500 })}
        loading={imageLoading}
      />
      <Title className="product-title">{title}</Title>
    </MagicLink>
  );
};

export default Insertion;
