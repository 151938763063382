import React from 'react';

import { Select } from '../../../components/form';
import { Icon } from '../../../components/icons';

import SearchIcon from '../../../icons/SearchFat.svg';

import { Wrapper, Search } from './Filters.styles';

import trads from '../trads';
import { useLocale } from '../../../services/i18n/useLocale';
import { JASOReformatViscosity } from '../../../utils/jaso';

const CategoryFilters = ({
  facets = [],
  selected = [],
  searchQuery = '',
  onFilter = () => null,
  onSearch = () => null,
  onSort = () => null,
  isSearch = false,
}) => {
  const intl = useLocale();
  const setFilter = React.useCallback(
    (option, values) => {
      const newSelected = selected.slice(0);
      let opt = selected.find((o) => o.name === option.name);

      if (!opt) {
        opt = {
          name: option.name,
        };
        newSelected.push(opt);
      }
      opt.values = values.map((o) => o.value);
      onFilter(newSelected);
    },
    [selected, onFilter]
  );

  const setSort = React.useCallback(
    (selected) => {
      const newSelected = selected.slice(0).shift();

      if (!newSelected) {
        return;
      }

      onSort(newSelected.value);
    },
    [onSort]
  );

  const sortOptions = React.useMemo(
    () => [
      {
        label: intl.formatMessage(trads.sortNatural),
        value: 'natural_asc',
      },
      {
        label: intl.formatMessage(trads.sortPrice),
        value: 'price_asc',
      },
      {
        label: intl.formatMessage(trads.sortPriceDown),
        value: 'price_desc',
      },
    ],
    [intl]
  );

  const prepOptions = facets
    .map((option) => {
      const current = selected.find((s) => s.name === option.name);
      const values = option.values.map((v) => ({
        label: JASOReformatViscosity(v.name),
        value: v.name,
      }));

      if (values.length < 2) {
        return null;
      }

      return {
        ...option,
        values,
        hasSelected: !!current?.values?.length,
      };
    })
    .filter((o) => o);

  const filterPlaceholder = new Array(Math.max(0, 2 - prepOptions.length)).fill(
    null
  );

  if (isSearch) {
    return (
      <Wrapper>
        <Search className="highlight">
          <div className="search-field-wrapper">
            <input
              type="text"
              id="categoryFilterSearch"
              onChange={(e) => onSearch(e.target.value)}
              aria-label={intl.formatMessage(trads.search)}
              placeholder={intl.formatMessage(trads.search)}
              value={searchQuery}
              className="search-field"
            />
            <Icon src={SearchIcon} />
          </div>
        </Search>
        <div className="product-grid filters">
          {prepOptions.map((option) => (
            <Select
              className={`select ${option.hasSelected && !option.isMulti ? 'dark' : ''
                }`}
              key={option.name}
              options={option.values}
              onChange={(selected) => setFilter(option, selected)}
              placeholder={option.name}
              multi={option.isMulti}
              clearable={option.hasSelected && !option.isMulti}
              dropdownPosition="auto"
            />
          ))}
          {filterPlaceholder.map((_, idx) => (
            <div key={idx} />
          ))}
          <div className="sort">
            <Select
              className="select grey"
              options={sortOptions}
              values={[sortOptions[0]]}
              searchable={false}
              onChange={setSort}
              dropdownPosition="auto"
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // console.log('*** facets', prepOptions, filterPlaceholder);
  return (
    <Wrapper className="product-grid">
      {prepOptions.map((option) => (
        <Select
          className={`select ${option.hasSelected && !option.isMulti ? 'dark' : ''
            }`}
          key={option.name}
          options={option.values}
          onChange={(selected) => setFilter(option, selected)}
          placeholder={option.name}
          multi={option.isMulti}
          clearable={option.hasSelected && !option.isMulti}
          dropdownPosition="auto"
        />
      ))}
      {filterPlaceholder.map((_, idx) => (
        <div key={idx} />
      ))}
      <Search>
        <div className="search-field-wrapper">
          <input
            type="text"
            id="categoryFilterSearch"
            onChange={(e) => onSearch(e.target.value)}
            aria-label={intl.formatMessage(trads.search)}
            placeholder={intl.formatMessage(trads.search)}
            value={searchQuery}
            className="search-field"
          />
          <Icon src={SearchIcon} />
        </div>
      </Search>
      <div className="sort">
        <Select
          className="select grey"
          options={sortOptions}
          values={[sortOptions[0]]}
          searchable={false}
          onChange={setSort}
          dropdownPosition="auto"
        />
      </div>
    </Wrapper>
  );
};

export default CategoryFilters;
