import styled from 'styled-components';



export const Icon = styled.svg`
  fill: none;
  stroke: #000;
  stroke-width: 2px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  display: none;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #FFF;
  border: 1px solid #000;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px #DFDFDF;
  }

  ${Icon} {
    visibility: hidden;
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  &.checked {
    ${Icon} {
      visibility: visible;
    }
  }
`;