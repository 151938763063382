import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import Seo, { BreadcrumbSnippet } from '../../components/seo';
import Search from '../../utils/ProductSearch';

import CategoryScreen from '../../screens/eshop/category';
import { Category } from '../../utils';
import { trackProductList } from '../../utils/gtm';

const CategoryTemplate = ({ data, pageContext }) => {
  const location = useLocation();
  const category = Category(data.category);
  const { subCategories = [], subCategoriesRoot } = pageContext;

  category.products = category.products
    .filter((p) => p.tags.indexOf('old') === -1)
    .map((p, idx) => ({
      ...p,
      trackList: 'Category',
      trackPosition: idx + 1,
    }));

  trackProductList(
    category.products.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  const search = new Search();
  search.index(category.products);

  const pageTitle = `${subCategoriesRoot?.title || ''} ${category.title
    }`.trim();

  return (
    <>
      <Seo
        title={pageTitle}
        description={category.description}
        preconnect={['https://cdn.shopify.com']}
      />
      <BreadcrumbSnippet
        list={[
          {
            name: pageTitle,
            url: location.pathname,
          },
        ]}
      />
      <CategoryScreen
        search={search}
        category={category}
        subCategories={subCategories}
        subCategoriesRoot={subCategoriesRoot}
      />
    </>
  );
};

export const query = graphql`
  query EshopCategory($shopifyId: String!, $locale: String!) {
    category: shopifyCollection(
      shopifyId: { eq: $shopifyId }
      locale: { eq: $locale }
    ) {
      id
      handle
      description
      descriptionHtml
      title
      # image {
      #   id
      #   src
      # }
      customfields {
        showConfigurator
        hideFilters
        searchpage
        seo_content
        grid {
          position
          image {
            cloudinary_src
            aspect_ratio
            alt
          }
          activated
          link
          title
        }
        faq {
          content
          title
        }
        cover {
          desktop {
            cloudinary_src
            aspect_ratio
            alt
          }
          mobile {
            cloudinary_src
            aspect_ratio
            alt
          }
        }
      }
      products {
        id
        ...ProductItem
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        tags
        options {
          name
          values
        }
      }
    }
  }
`;
export default CategoryTemplate;
