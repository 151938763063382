import React from 'react';

import { useWidget } from '../widgets';
import { Imgix, getConfiguratorUrl } from '../../utils';

import { Wrapper, Title, Brands, More, Logo } from './Preview.style';
import { useLocale } from '../../services/i18n/useLocale';
import MagicLink from '../link';

const Comp = ({ className }) => {
  const {
    title,
    // text,
    linkName,
    brands,
  } = useWidget('configurator');
  const intl = useLocale();
  const url = getConfiguratorUrl(undefined, intl.locale);

  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      <Brands>
        {brands.slice(0, 4).map((brand, index) => (
          <Logo key={index}>
            <MagicLink
              className="box"
              href={`${url}/${`${brand.logo.alt}`
                .toLowerCase()
                .replace(' ', '-')}`}
            >
              <Imgix
                {...brand.logo}
                width={70}
                height={45}
                fadeIn={false}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            </MagicLink>
          </Logo>
        ))}
      </Brands>
      <More>
        <MagicLink href={url}>{linkName}</MagicLink>
      </More>
    </Wrapper>
  );
};

export default Comp;
