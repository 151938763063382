import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  width: 100%;

  /* .configurator-wrapper {
    box-shadow: 0 24px 20px 0 rgba(139, 140, 145, 0.05);
    border: solid 1px #eeeeee;
    padding: 20px 10px;
    margin: 20px 0 60px 0;
  } */
  .configurator-title {
    padding: 0 20px;
    margin: 0;
  }

  .configurator {
    /* margin-top: 10px; */
    margin-bottom: 30px;

    @media ${device.tabletDown} {
      margin-left: -20px;
      margin-right: -20px;
    }
  
    @media ${device.tablet} {
      border: 2px solid #F3F3F3;
    }
  }

  .more-container {
    width: 100%;
    text-align: center;
  }

  button.more {
    text-transform: uppercase;
    border: 1px solid #000;
    transform: skew(-15deg);
    padding: 10px 30px;
    margin: 0px auto;
    transition: background-color 200ms;
    margin-bottom: 50px;

    .inner {
      transform: skew(15deg);
    }

    &:hover {
      background: #000;
      border-color: #FFF;
      color: #FFF;
    }
  }

  .additional-content {
    flex: 1;
    padding: 20px 30px;
    margin: 40px 0;
    background-color: #fafafa;

    p, a {
      font-size: 14px;
    }
  }
`;

export const NoProduct = styled.div`
  width: 100%;
  text-align: center;
  margin: 80px 20px;
  font-weight: bold;
`;

export const Cover = styled.div`
  background-color: #e9e9e9;

  .desktop.gatsby-image-wrapper {
    width: 100%;
    min-height: 180px;
    max-height: 300px;
  }

  .container {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
  }

  .mobile {
    @media ${device.tablet} {
      display: none;
    }
  }

  .mobile + .desktop {
    @media ${device.tabletDown} {
      display: none!important;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  text-align: center;
  margin: 20px 0px;
  justify-content: center;
  align-items: center;

  .red-mark {
    display: inline-block;
    width: 20px;
    height: 25px;
    background: #E1251B;
    transform: skew(-20deg);
    margin-right: 15px;

    @media ${device.tablet} {
      /* width: 25px;
      height: 30px; */
    }
  }


  @media ${device.tabletDown} {
    .bigtitle {
      font-size: 20px;
    }
  }

  @media ${device.tablet} {
    margin: 20px 20px;
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 30px 0;

  @media ${device.tablet} {
    margin: 30px 0 60px 0;
  }
`;
