import React, { useState } from 'react';
import Img from 'gatsby-image';

import Filters from './Filters';
import Configurator from '../../../components/configurator/Preview';

import { ProductItem } from '../../../components/eshop';
import HtmlRender from '../../../components/common/Html';
import Menu from '../range/Menu';

import { CloudinaryFluid } from '../../../utils';

import trads from '../trads';

import {
  Wrapper,
  Cover,
  Title,
  ProductsContainer,
  NoProduct,
} from './Category.styles';
import { useLocale } from '../../../services/i18n/useLocale';
import Insertion from './Insertion';
import MagicLink from '../../../components/link';
import PageFAQ from '../product/FAQ';

const saveFilters = {};

const CategoryPage = (props) => {
  const { category, search, subCategories = [], subCategoriesRoot } = props;
  const {
    showConfigurator = false,
    hideFilters = false,
    searchpage = false,
    cover = [],
    seo_content,
    grid: insertions = [],
  } = category.customfields || {};
  const memo = saveFilters[category.id] || {};

  const intl = useLocale();
  const [filters, setFilters] = useState(memo.filters || []);
  const [page, setPage] = useState(memo.page || 1);
  const [sort, setSort] = useState(memo.sort || 'natural_asc');
  const [query, setQuery] = useState(memo.query || '');

  const insertionsIndex = (insertions || []).reduce(
    (acc, item, idx) => {
      const newItem = { ...item, isInsertion: true };
      const position = parseInt(newItem.position, 10) - 1;

      if (idx === 0) {
        acc.firstPosition = position;
      }

      if (
        newItem.image &&
        Array.isArray(newItem.image) &&
        newItem.image.length > 0
      ) {
        newItem.image = newItem.image[0];
      } else {
        newItem.image = null;
      }
      acc[position] = newItem;
      return acc;
    },
    { firstPosition: -1 }
  );

  saveFilters[category.id] = {
    filters,
    page,
    sort,
    query,
  };

  const pageSize = 10 * 4;
  const limit = page * pageSize;

  // console.log('Filter category', query, filters, sort);

  const products = search.where({
    query,
    options: filters,
    sort,
    limit,
  });

  let coverDesktop;
  let coverMobile;

  if (cover && cover.length && cover[0].desktop) {
    coverDesktop = CloudinaryFluid(cover[0].desktop, { maxWidth: 1600 });
  }

  if (cover && cover.length && cover[0].mobile) {
    coverMobile = CloudinaryFluid(cover[0].mobile, { maxWidth: 600 });
  }

  const grid = products.results.reduce((res, product) => {
    const idx = res.length;
    const insertion = insertionsIndex[idx];
    insertion && res.push(insertion);
    res.push(product);

    return res;
  }, []);

  if (grid.length - 1 < insertionsIndex.firstPosition) {
    grid.push(insertionsIndex[insertionsIndex.firstPosition]);
  }

  // console.log('search result', searchpage);
  return (
    <Wrapper>
      {coverDesktop ? (
        <Cover>
          {!!coverMobile && <Img fluid={coverMobile} className="mobile" />}
          <Img fluid={coverDesktop} className="desktop" />
        </Cover>
      ) : null}

      <div className="container">
        <Title>
          <div className="red-mark" />
          {subCategoriesRoot?.title ? (
            <MagicLink to={subCategoriesRoot.url}>
              <h1 className="bigtitle">
                {subCategoriesRoot?.title} {category.title}
              </h1>
            </MagicLink>
          ) : (
            <h1 className="bigtitle">{category.title}</h1>
          )}
        </Title>
      </div>
      {!!subCategories.length && <Menu entries={subCategories} />}
      <div className="container">
        {showConfigurator ? (
          <div className="configurator-wrapper">
            <Configurator className="configurator" />
          </div>
        ) : null}
        {!hideFilters && (
          <Filters
            facets={search.facets}
            selected={filters}
            onFilter={setFilters}
            onSearch={setQuery}
            searchQuery={query}
            sort={sort}
            onSort={setSort}
            isSearch={searchpage}
          />
        )}
        {products.results?.length ? (
          <ProductsContainer className="product-grid">
            {grid.map((product, idx) => {
              if (product.isInsertion) {
                return (
                  <Insertion
                    key={idx}
                    {...product}
                    imageLoading={idx < 2 ? 'eager' : 'lazy'}
                  />
                );
              }
              return (
                <ProductItem
                  key={product.id}
                  {...product}
                  displayFrom={
                    product.options.reduce(
                      (total, i) => total + i.values?.length || 0,
                      0
                    ) > 1
                  }
                  imageLoading={idx < 2 ? 'eager' : 'lazy'}
                />
              );
            })}
          </ProductsContainer>
        ) : (
          <NoProduct>{intl.formatMessage(trads.noProducts)}</NoProduct>
        )}
        {products.total > limit ? (
          <div className="more-container">
            <button
              className="button clear more"
              onClick={() => setPage((prev) => ++prev)}
            >
              <div className="inner">{intl.formatMessage(trads.viewMore)}</div>
            </button>
          </div>
        ) : null}
        {!!category.customfields?.faq?.length && (
          <PageFAQ sections={category.customfields.faq} />
        )}
        {!!seo_content && (
          <div className="additional-content">
            <HtmlRender html={seo_content} className="content" />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default CategoryPage;
