import Flexsearch from 'flexsearch';
import Qty from 'js-quantities';
import clothesSort from 'apparel-sorter';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const intersection = (...lists) => {
  const result = [];

  for (var i = 0; i < lists.length; i++) {
    var currentList = lists[i];
    for (var y = 0; y < currentList.length; y++) {
      var currentValue = currentList[y];
      if (result.indexOf(currentValue) === -1) {
        var existsInAll = true;
        for (var x = 0; x < lists.length; x++) {
          if (lists[x].indexOf(currentValue) === -1) {
            existsInAll = false;
            break;
          }
        }
        if (existsInAll) {
          result.push(currentValue);
        }
      }
    }
  }
  return result;
};

const comparePrice =
  (asc = true) =>
  (a, b) => {
    const aPrice = parseFloat(a?.defaultVariant?.price, 10) || 0;
    const bPrice = parseFloat(b?.defaultVariant?.price, 10) || 0;

    if (aPrice === bPrice) {
      return 0;
    }
    if (asc) {
      return aPrice - bPrice;
    }
    return bPrice - aPrice;
  };

const getNGrams = (s, len) => {
  s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
  let v = new Array(s.length - len + 1);
  for (let i = 0; i < v.length; i++) {
    v[i] = s.slice(i, i + len);
  }
  return v;
};

const stringSimilarity = (str1, str2, gramSize = 2) => {
  if (!str1?.length || !str2?.length) {
    return 0.0;
  }

  let s1 = str1.length < str2.length ? str1 : str2;
  let s2 = str1.length < str2.length ? str2 : str1;

  let pairs1 = getNGrams(s1, gramSize);
  let pairs2 = getNGrams(s2, gramSize);
  let set = new Set(pairs1);

  let total = pairs2.length;
  let hits = 0;
  for (let item of pairs2) {
    if (set.delete(item)) {
      hits++;
    }
  }
  return hits / total;
};

const optionSort = (option) => {
  if (option.name.toLowerCase() === 'taille') {
    return Object.values(option.values).sort((a, b) => {
      const aScore = clothesSort.numberify(a.name);
      const bScore = clothesSort.numberify(b.name);

      if (aScore === bScore) {
        return a.name.length - b.name.length;
      }

      return aScore - bScore;
    });
  }

  return Object.values(option.values).sort((a, b) => {
    try {
      const aQ = Qty(a.name);
      const bQ = Qty(b.name);
      const kindCompare = aQ.kind().localeCompare(bQ.kind());

      if (kindCompare === 0) {
        return aQ.compareTo(bQ);
      } else {
        return kindCompare;
      }
    } catch (e) {}

    return collator.compare(a.name, b.name);
  });
};
const clearString = (str) => {
  let newStr = `${str}`
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace('-', '');
  newStr = newStr.replace(' de ', ' ');
  return newStr;
};

class ProductSearch {
  facets = [];
  products = [];
  _fastIndex = {};

  constructor() {
    this._fulltext = new Flexsearch({
      profile: 'match',
      tokenize: 'full',
      doc: {
        id: 'id',
        field: [
          'title',
          // 'description',
          'productType',
          'options',
        ],
      },
    });
  }

  index = (docs) => {
    this.products = docs;
    // console.time('index');
    this._fulltext.add(
      docs.map((doc) => ({
        id: doc.id,
        // description: doc.description,
        title: clearString(doc.title),
        productType: clearString(doc.productType),
        defaultVariant: doc.defaultVariant,
        options: doc.options
          .reduce((res, item) => res.concat(item.values), [])
          .join(' / '),
      }))
    );

    const options = {};
    docs.forEach((doc) => {
      doc.options.forEach((opt) => {
        if (opt.name === 'Title') {
          return;
        }
        if (!options[opt.name]) {
          options[opt.name] = {
            name: opt.name,
            values: {},
          };
        }

        const idx = options[opt.name];

        opt.values.forEach((value) => {
          let c = idx.values[value];
          if (!idx.values[value]) {
            c = idx.values[value] = {
              count: 0,
              name: value,
            };
          }
          c.count++;

          const key = `${opt.name}:${value}`.toLowerCase();

          if (!this._fastIndex[key]) {
            this._fastIndex[key] = [];
          }

          this._fastIndex[key].push(doc.id);
        });
      });
    });

    this.facets = Object.values(options).map((options) => ({
      ...options,
      isMulti: options.name === 'Contenance',
      values: optionSort(options),
    }));
    // console.timeEnd('index');
  };

  search = (...args) => {
    return this._fulltext.search(...args);
  };

  facets = () => {
    return Object.values(this.options);
  };

  sort = (responses, sortRaw) => {
    const sort = `${sortRaw}`.split('_');
    const sortKey = sort.length ? sort[0] : null;
    const asc = sort.length > 1 ? sort[1] === 'asc' : true;

    let sortFn;

    console.log(sort, sortKey, responses);

    if (sortKey === 'price') {
      sortFn = comparePrice(asc);
    } else {
      return responses;
    }
    return responses.sort(sortFn);
  };

  limit = (responses, limit) => {
    const total = responses.length;
    const results = responses.slice(0, limit);

    return {
      total,
      results,
    };
  };

  rehydrate = (resp) => {
    return {
      ...resp,
      results: resp.results.map((doc) =>
        this.products.find((p) => p.id === doc.id)
      ),
    };
  };

  autosuggest = (query) => {
    const docs = this._fulltext
      .where((_) => true)
      .map((doc) => {
        return {
          id: doc.id,
          title: doc.title,
          score: stringSimilarity(doc.title, query),
        };
      })
      .sort((a, b) => b.score - a.score)
      .filter((a) => a.score > 0.25);

    return docs;
  };

  where = ({ query = '', options = [], sort, limit } = {}) => {
    // console.time('search');
    let filtered = [];
    const cleanQuery = clearString(query);
    const sOptions = options.filter((option) => option.values.length); // remove empty option

    console.log('Search', cleanQuery, sOptions, sort);
    if (sOptions.length) {
      filtered = sOptions.map((o) => {
        const prefix = o.name;

        const results = o.values
          .map((v) => `${prefix}:${v}`.toLowerCase()) // transform value to index key
          .map((key) => this._fastIndex[key]) // get associated docs from index
          .reduce((acc, products) => {
            // merge all values to unique array
            return [...acc, ...products];
          }, []);

        // filter unique
        return results.filter((item, idx) => results.indexOf(item) === idx);
      });

      // console.log('Before intersection ', filtered);

      filtered = intersection(...filtered); // keep only the common ids across all options

      // If no selection, return no results
      if (!filtered.length) {
        return [];
      }
    }

    // console.log('filter docs', filtered);
    const where = (doc) =>
      !filtered.length ? true : filtered.indexOf(doc.id) !== -1;

    let result;

    if (!cleanQuery) {
      result = this.rehydrate(
        this.limit(this.sort(this._fulltext.where(where), sort), limit)
      );
    } else {
      result = this.rehydrate(
        this.limit(
          this.sort(
            this._fulltext.search({
              query: cleanQuery,
              suggest: true,
              where,
            }),
            sort
          ),
          limit
        )
      );
    }

    // if (result.total === 0) {
    //   console.log('AUTO SUGGEST');
    //   result = this.rehydrate(
    //     this.limit(
    //       this.sort(
    //         this.autosuggest(cleanQuery),
    //         sort
    //       ),
    //       limit
    //     ),
    //   )
    // }

    // console.log('RESULT', result);
    // console.timeEnd('search');
    // console.log('full search', cleanQuery);
    return result;
  };
}

export default ProductSearch;
