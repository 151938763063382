import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  .sort {
    &, .select-wrapper {
      height: 100%;
    }
  }

  .select {
  }

  .filters {
    margin-top: 20px;
  }

  margin-bottom: 30px;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`;

export const Search = styled.div`
  .search-field-wrapper {
    position: relative;
    height: 100%;
  }

  input {
    border: solid 1px #ececec;
    padding: 0px 15px 0 35px;
    font-size: 13px;
    letter-spacing: 0.1px;
    outline: none;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    @media ${device.tablet} {
      font-size: 15px;
    }

    &:hover, &:focus {
      border-color: #000;
    }

    &::placeholder {
      color: #000;
    }

    
  }

  .icon {
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 15px;
    width: 14px;
    height: 14px;
  }

  &.highlight {

    .search-field-wrapper {
      @media ${device.tablet} {
        max-width: calc(50% - 30px);
      }
      margin: 0 auto;
    }
    input {
      height: 50px;
      border-color: grey;
      width: 100%;
      font-size: 16px;
    }
  }
`;