import styled from 'styled-components';
import {
  device
} from '../../utils';

export const Wrapper = styled.div `
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
  padding: 45px 0;
  background: #000;
  color: #FFF;

  @media ${device.tablet} {
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    justify-content: space-between;
  }

  @media ${device.laptop} {
    
  }

  .box {
    line-height: 0;
    height: 100%;
    display: block;
  }
`;

export const Title = styled.h3 `
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin: 0;
  
  @media ${device.tablet} {
    text-align: left;
    max-width: 240px;
  }
`;

export const Logo = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #A9A9A9;
  height: 100%;
  text-align: center;
  cursor: pointer;
  background: #252525;
  border-radius: 45px;
  transition: border-radius 300ms ease, background-color 300ms ease;
  backface-visibility: hidden;
  height: 100%;

  @media ${device.laptop} {
    padding: 10px 25px;
  }

  @media (hover: hover) {
    &:hover {
      border-radius: 25px;
      background: #3C3C3C;
      /* transform: scale(1.1); */
    }
  }
`;

export const Brands = styled.div `
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  max-width: 100%;
  margin: 35px 0;

  @media ${device.laptopDown} {
    ${Logo} {
      &:nth-child(n+4) {
        display: none;
      }
    }
  }

  @media ${device.laptop} {
    margin: 0;
    flex: 1;
    justify-content: space-evenly;
    margin: 0 50px;
  }
`;

export const More = styled.div `
  a {
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    font-family: 'Montserrat';
    text-decoration: underline;
    text-underline-offset: 2px;

    &:after {
      display: inline-block;
      content: '>';
      text-decoration: none;
      margin-left: 5px;
    }

    @media ${device.laptop} {
      font-size: 16px;
    }
  }
`;