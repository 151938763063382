import styled from 'styled-components';
import { device } from '../../../utils';

export const Title = styled.h3`
  font-family: 'Montserrat';
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  margin: 20px 0 3px;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 15px;
    margin: 20px 0 5px;
  }
`;
