import React from 'react';
import Select from 'react-dropdown-select';
import csx from 'classnames';

import {
  Wrapper
} from './Select.styles';

const Selector = (props) => {
  return (
    <Wrapper
      className={csx(
        "select-wrapper",
        {
          "not-searchable": props.searchable === false,
        }
      )}
    >
      <Select
        color="#000000"
        {...props}
      />
    </Wrapper>
  );
}

export default Selector;
